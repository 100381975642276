<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <accion-correctora-form
          v-if="anomalia && anomalia.tanomalia_checklist_ot && dbReady"
          :id="null"
          :idtanomalia-checklist-ot="anomalia.tanomalia_checklist_ot.idtanomalia_checklist_ot"
          :idtfichero="idtfichero"
          :has-insert-accion-correctora-adjunto-perm="hasInsertAccionCorrectoraAdjuntoPerm"
          :material-afectado="materialAfectado"
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import { TFICHERO } from '@/utils/consts'
import AccionCorrectoraForm from '../components/AccionCorrectoraForm'
import Data from './AccionCorrectoraAddData'
import { get } from 'vuex-pathify'

export default {
  components: {
    AccionCorrectoraForm
  },
  mixins: [formPageMixin],
  data () {
    return {
      idtfichero: null,
      anomalia: {},
      hasInsertAccionCorrectoraAdjuntoPerm: true,
      materialAfectado: {},
    }
  },
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      this.title = 'Añadir acción correctora'
      this.hasInsertAccionCorrectoraAdjuntoPerm = this.hasInsertPerm(this.permissions.accionCorrectoraAdjunto.id)
      const tfichero = await this.$offline.tfichero.rowTficheroClasificacion(
        TFICHERO.clasificacion.adjuntoAccionCorrectora
      )
      this.idtfichero = tfichero[0].idtfichero
      if (this.idtfichero === null) {
        this.$alert.showSnackbarError('No existe un tipo de ficheros definido para acciones correctoras')
      }
      this.anomalia = await this.$offline.tanomaliaChecklistOt.row(this.routeParams.idtanomalia_checklist_ot)
      // Tengo que mandarle al form el idarticulo del material de sistema para saber qué artículo va a facturar si tiene maccion
      this.materialAfectado = await this.$offline.preguntaChecklistOt.selectMaterialSistemaDePreguntaChecklistOt(this.routeParams.idpregunta_checklist_ot)
    },
    async submitForm () {
      const idaccionCorrectoraOt = await Data.insert(
        this,
        this.formData,
        this.routeParams.idtanomalia_checklist_ot,
        this.idtfichero,
        this.routeParams.idparte_trabajo
      )
      this.$appRouter.replace({
        name: 'offline__accion-correctora-view',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          idchecklist_ot: this.routeParams.idchecklist_ot,
          idgrupo_checklist_ot: this.routeParams.idgrupo_checklist_ot,
          idpregunta_checklist_ot: this.routeParams.idpregunta_checklist_ot,
          idtanomalia_checklist_ot: this.routeParams.idtanomalia_checklist_ot,
          idaccion_correctora_ot: idaccionCorrectoraOt,
        },
      })
    },
  }
}
</script>
